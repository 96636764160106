import React from 'react';
import OCRLine from './OCRRegionLine';
import { ocrBoundingBoxToPosition } from './ocrService';

const OCRRegion = ({
  region,
  scale,
  visibleWords,
  outlinedLines,
  outlinedWords,
  disableWordsText
}) => {
  const { top, left, width, height } = ocrBoundingBoxToPosition(region.BoundingBox);

  return (
    <>
      <div style={{
        position: 'absolute',
        top: top * scale,
        left: left * scale,
        width: width * scale,
        height: height * scale,
        fontFamily: 'Open Sans',
        outline: outlinedLines ? '1px solid red' : 'none'
      }}>
      </div>
        {region.Lines.map((line, lineIndex) => (
          <OCRLine 
            key={`line-${lineIndex}`}
            scale={scale}
            line={line}
            visibleWords={visibleWords}
            outlined={outlinedLines}
            outlinedWords={outlinedWords}
            disableWordsText={disableWordsText}
            parentTop={top}
            parentLeft={left} />
        ))}
    </>
  )
}

export default OCRRegion;