import React from 'react';
import {
    getFieldScoreFromBludeltaStore,
    getFieldValueFromBludeltaStore,
    getTypeNameByRef,
} from './formService';
import FormInput from './FormInput';
import {Add} from "@material-ui/icons";
import {InputAdornment, FormHelperText, Button, FormControl, InputLabel, Divider, Typography} from "@material-ui/core";

const FormControlWrapper = ({
                                field,
                                bludeltaMap,
                                onInputChanged,
                                onFormInputFocus,
                                copy,
                                groupIndex,
                                error,
                                errorMessage,
                                minimumScore,
                                index,
                                showScores,
                                croppedData,
                                classes,
                                highlightedDetection
                            }) => {
    // const getFieldRefValue = (ref, defaultVal, minimumScore) => (getFieldValueFromBludeltaStore(bludeltaMap, ref, defaultVal, minimumScore));
    const getFieldRefValue = (ref, defaultVal, minimumScore) => {
        const val = getFieldValueFromBludeltaStore(bludeltaMap, ref, defaultVal, minimumScore);

        if (val === undefined) {
            return '';
        }

        return val;
    };


    const [inputFieldData, setInputFieldData] = React.useState(field);
    const [focusByHighlightingOcr, setfocusByHighlightingOcr] = React.useState(false);
    const [fieldFocus, setFieldFocus] = React.useState(false);
    const [score, setScore] = React.useState(getFieldScoreFromBludeltaStore(bludeltaMap, field.BluDeltaFieldType));

    React.useEffect(() => {
        if (highlightedDetection && inputFieldData.BluDeltaFieldType) {
            const refTypeName = getTypeNameByRef(inputFieldData.BluDeltaFieldType);

            setFieldFocus(highlightedDetection.Type === refTypeName);
        }
    }, [highlightedDetection]);

    React.useEffect(() => {
        setInputFieldData(field);
        /** Handle MinimumScore here */
        if (inputFieldData.hasOwnProperty('MinimumScore')) {
            setScore(inputFieldData.MinimumScore);
        }
    }, [field]);

    const InputLabelDisplay = props => {
        return (
            inputFieldData.InputType === undefined || inputFieldData.InputType === 'text' || (inputFieldData.InputType === 'select' && inputFieldData.OptionListHandling === 'strict') ? (
                <InputLabel htmlFor={inputFieldData.Name}>
                    {inputFieldData.DisplayName}
                </InputLabel>
            ) : null
        )
    }

    return (
        <div className="form-field-wrapper" style={{
            textAlign: 'left !important'
        }}>
            <FormControl style={{width: '100%'}} error={error}>

                <InputLabelDisplay/>

                <FormInput
                    croppedData={croppedData}
                    autoFocus={fieldFocus}
                    classes={classes}
                    onInputChanged={value => onInputChanged(value, inputFieldData.BluDeltaFieldType)}
                    dataInputResourceType={inputFieldData.ValueType === 'BludeltaRef' ? getTypeNameByRef(inputFieldData.BluDeltaFieldType) : null}
                    id={inputFieldData.Name}
                    value={inputFieldData.Value}
                    readOnly={inputFieldData.Readonly}
                    multiple={inputFieldData.Multiple}
                    type={inputFieldData.InputType}
                    optionList={inputFieldData.OptionList}
                    optionListHandling={inputFieldData.OptionListHandling}
                    copy={() => copy(inputFieldData, true, groupIndex, index)}
                    onFocus={() => onFormInputFocus(inputFieldData.BluDeltaFieldType)}
                    endAdornment={
                        <Add
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                cursor: 'pointer',
                                display: 'inline-block',
                                margin: 'auto'
                            }}
                            onClick={() => copy(inputFieldData, true, groupIndex, index)}/>
                    }
                    label={inputFieldData.DisplayName}/>

                {error && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
            {showScores && <Typography variant="caption">Score: {score}</Typography>}
        </div>
    )
};

export default FormControlWrapper;