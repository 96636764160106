import axios from "axios";

export const getFieldScoreFromBludeltaStore = (bludeltaMap, ref) => {

    if (ref) {
        if (ref.includes('PredictionGroups')) { //PredictionGroups
            if (ref.includes("['.']")) {
                const indexOfTypeName = ref.indexOf('TypeName');

                const typeNameVal = ref
                    .substring(indexOfTypeName)
                    .replace("TypeName['", '')
                    .replace("']", '');

                if (bludeltaMap.PredictionGroups[typeNameVal]) {
                    return bludeltaMap.PredictionGroups[typeNameVal].Score;
                }
            }

        } else if (ref.includes('InvoiceDetailTypePredictions')) { //InvoiceDetailTypePredictions
            const indexOfTypeName = ref.indexOf('TypeName');
            const typeNameVal = ref
                .substring(indexOfTypeName)
                .replace("TypeName['", '')
                .replace("']", '');

            if (bludeltaMap.InvoiceDetailTypePredictions[typeNameVal]) {
                return bludeltaMap.InvoiceDetailTypePredictions[typeNameVal].Score;
            }
        } else if (ref.includes('Sender')) { // Sender
            const indexOfTypeName = ref.indexOf('Sender');
            const typeNameVal = ref
                .substring(indexOfTypeName)
                .replace(".Value", '')
                .replace(".", '');

            if (bludeltaMap.Sender[typeNameVal]) {
                return bludeltaMap.Sender[typeNameVal].Score;
            }

        } else if (ref.includes('Receiver')) {
            const indexOfTypeName = ref.indexOf('Receiver');
            const typeNameVal = ref
                .substring(indexOfTypeName)
                .replace(".Value", '')
                .replace(".", '');

            if (bludeltaMap.Receiver[typeNameVal]) {
                return bludeltaMap.Receiver[typeNameVal].Score;
            }
        }
    }

    return 0;
};

export const getFieldValueFromBludeltaStore = (bludeltaMap, ref, defaultVal, minimumScore) => {
    if (ref.includes('PredictionGroups')) { //PredictionGroups
        if (ref.includes("['.']")) {
            const indexOfTypeName = ref.indexOf('TypeName');

            const typeNameVal = ref
                .substring(indexOfTypeName)
                .replace("TypeName['", '')
                .replace("']", '');

            const prediction = bludeltaMap.PredictionGroups[typeNameVal];
            if (prediction) {
                return minimumScore <= prediction.Score ? prediction.Value : defaultVal;
            }

            return defaultVal || '';

        } else {
            return defaultVal || '';
        }

    } else if (ref.includes('InvoiceDetailTypePredictions')) { //InvoiceDetailTypePredictions
        const indexOfTypeName = ref.indexOf('TypeName');
        const typeNameVal = ref
            .substring(indexOfTypeName)
            .replace("TypeName['", '')
            .replace("']", '');

        const prediction = bludeltaMap.InvoiceDetailTypePredictions[typeNameVal];
        if (prediction) {
            return minimumScore <= prediction.Score ? prediction.Value : defaultVal;
        }

        return defaultVal || '';
    } else if (ref.includes('Sender')) { // Sender
        const indexOfTypeName = ref.indexOf('Sender');
        const typeNameVal = ref
            .substring(indexOfTypeName)
            .replace(".Value", '')
            .replace(".", '');

        const sender = bludeltaMap.Sender[typeNameVal];
        if (sender) {
            return minimumScore <= sender.Score ? sender.Value : defaultVal;
        }

        return defaultVal || '';

    } else if (ref.includes('Receiver')) {
        const indexOfTypeName = ref.indexOf('Receiver');
        const typeNameVal = ref
            .substring(indexOfTypeName)
            .replace(".Value", '')
            .replace(".", '');

        const receiver = bludeltaMap.Receiver[typeNameVal];
        if (receiver) {
            return minimumScore <= receiver.Score ? receiver.Value : defaultVal;
        }

        return defaultVal || '';
    }

    return defaultVal || '';
};

export const updateFieldValueFromBludeltaStore = (bludeltaMap, ref, val) => {
    if (ref.includes('PredictionGroups')) { //PredictionGroups
        if (ref.includes("['.']")) {
            const indexOfTypeName = ref.indexOf('TypeName');

            const typeNameVal = ref
                .substring(indexOfTypeName)
                .replace("TypeName['", '')
                .replace("']", '');

            if (bludeltaMap.PredictionGroups[typeNameVal]) {
                bludeltaMap.PredictionGroups[typeNameVal].Value = val;
            }

            return bludeltaMap;

        } else {
            return bludeltaMap;
        }

    } else if (ref.includes('InvoiceDetailTypePredictions')) { //InvoiceDetailTypePredictions
        const indexOfTypeName = ref.indexOf('TypeName');
        const typeNameVal = ref
            .substring(indexOfTypeName)
            .replace("TypeName['", '')
            .replace("']", '');

        if (bludeltaMap.InvoiceDetailTypePredictions[typeNameVal]) {
            bludeltaMap.InvoiceDetailTypePredictions[typeNameVal].Value = val;
        }

        return bludeltaMap;

    } else if (ref.includes('Sender')) { // Sender
        const indexOfTypeName = ref.indexOf('Sender');
        const typeNameVal = ref
            .substring(indexOfTypeName)
            .replace(".Value", '')
            .replace(".", '');

        if (bludeltaMap.Sender[typeNameVal] && bludeltaMap.Sender[typeNameVal].Value) {
            bludeltaMap.Sender[typeNameVal].Value = val;
        }

        return bludeltaMap;

    } else if (ref.includes('Receiver')) {
        const indexOfTypeName = ref.indexOf('Receiver');
        const typeNameVal = ref
            .substring(indexOfTypeName)
            .replace(".Value", '')
            .replace(".", '');

        if (bludeltaMap.Receiver[typeNameVal] && bludeltaMap.Receiver[typeNameVal].Value) {
            bludeltaMap.Receiver[typeNameVal].Value = val;
        }

        return bludeltaMap;
    }
};

export const getTypeNameByRef = ref => {
    let typeNameVal;


    if (ref.includes('PredictionGroups')) { //PredictionGroups
        if (ref.includes("['.']")) {
            const indexOfTypeName = ref.indexOf('TypeName');

            typeNameVal = ref
                .substring(indexOfTypeName)
                .replace("TypeName['", '')
                .replace("']", '');
            return typeNameVal;
        } else {
            return null;
        }

    } else if (ref.includes('InvoiceDetailTypePredictions')) { //InvoiceDetailTypePredictions
        const indexOfTypeName = ref.indexOf('TypeName');
        typeNameVal = ref
            .substring(indexOfTypeName)
            .replace("TypeName['", '')
            .replace("']", '');

        return typeNameVal;

    } else if (ref.includes('Sender')) { // Sender
        const indexOfTypeName = ref.indexOf('Sender');
        typeNameVal = ref
            .substring(indexOfTypeName)
            .replace(".Value", '')
            .replace(".", '');

        return typeNameVal;

    } else if (ref.includes('Receiver')) {
        const indexOfTypeName = ref.indexOf('Receiver');
        typeNameVal = ref
            .substring(indexOfTypeName)
            .replace(".Value", '')
            .replace(".", '');

        return typeNameVal || '';
    }
};

export const validateForm = (formData, formErrors) => {
    formData.FieldGroups.forEach((group, groupIndex) => {
        group.Fields.forEach((field, fieldIndex) => {
            formErrors = validateField(field.Value, field, formErrors, groupIndex, fieldIndex);
        })
    });

    return formErrors;
};

export const validateField = (value, field, formErrors, groupIndex, fieldIndex) => {
    if (field.Validate) {
        try {
            const regex = new RegExp(`${field.Validate}`);

            if (!regex.test(value)) {
                formErrors[groupIndex][fieldIndex] = field.ValidationErrorMessage;
            } else {
                formErrors[groupIndex][fieldIndex] = '';
            }
        } catch (e) {
            // Invalid regex, skip
        }
    }

    return formErrors;
};

export const initializeFormErrors = formData => {
    let indexes = [];
    for (let i = 0; i < formData.FieldGroups.length; i++) {
        const arr = [];
        formData.FieldGroups[i].Fields.forEach((f, j) => arr[j] = '');
        indexes.push(arr);
    }
    return indexes;
};

export const submit = formData => {
    formData = JSON.parse(JSON.stringify(formData));
    if (!formData.Form.SendInvoiceFile) delete formData['Invoice'];
    if (!formData.Form.SendBluedeltaResult) delete formData['BluDeltaResult'];

    return axios({
        method: formData.Form.FormMethode,
        url: formData.Form.FormAction,
        headers: {
            'Access-Control-Allow-Origin': '*'
        },
        data: formData
    });
};