import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {format} from 'date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
    KeyboardDatePicker
} from '@material-ui/pickers';
import {TextField, Button, FormGroup, Checkbox, FormControlLabel, MenuItem, Select, Input, Typography} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Add} from "@material-ui/icons";

const FormInput = props => {
    const {
        id,
        value,
        readonly,
        type,
        error,
        label,
        onFocus,
        dataInputResourceType
    } = props;

    const {
        croppedData,
        optionList,
        onInputChanged,
        optionListHandling,
        endAdornment,
        multiple,
        copy,
        classes,
        ...htmlAttributes
    } = props;
    const [inputFocus, setInputFocus] = React.useState(props.autoFocus);
    const [selectionStart, setSelectionStart] = React.useState(0);
    const [selectionEnd, setSelectionEnd] = React.useState(0);
    const inputRef = React.createRef();

    const isValidDate = (d) => {
      return d instanceof Date && !isNaN(d);
    }

    const onInputChangeTriggered = val => {
        if (type === 'date') {
          const nd = new Date(val);

          if(isValidDate(nd)) {
            const valFormatted = format(new Date(val), 'yyyy-MM-dd');
            onInputChanged(valFormatted);
          }
            
        } else {
            onInputChanged(val);
        }
    };

    const [fieldValue, setFieldValue] = React.useState(props.value);

    React.useEffect(() => {
        let val = value;
        /** Set value to null when date value is undefined. Date picker can work with null, but can't with undefined */
        if (type === 'date') {
            if (!val || val === ' ') {
                val = null;
            }
        }
        /** Label overflows the input value when value is undefined */
        if (type !== 'date') {
            val = val ? val : '';
        }

        setFieldValue(val);
    }, [props.value]);

    React.useEffect(() => {
      if(type === 'text' || (type === 'select' && inputRef !== 'strict')) {
        if(inputRef && inputRef.current) {
          inputRef.current.selectionStart = selectionStart;
          inputRef.current.selectionEnd = selectionEnd;
        }
      }
    }, [fieldValue])

    React.useEffect(() => {
      setInputFocus(props.autoFocus);
    }, [props.autoFocus]);

    const onInputFocusTriggered = e => {
        onFocus();
    };

    switch (type) {
        case 'select':
            if (optionListHandling !== 'strict') {
                let foundOptionInputValue = optionList.filter(o => o.Value === fieldValue);
                
                if(foundOptionInputValue && foundOptionInputValue.length) {
                  foundOptionInputValue = foundOptionInputValue[0].DisplayValue;
                } else {
                  foundOptionInputValue = fieldValue;
                }

                return <React.Fragment>
                    <Autocomplete
                      disableOpenOnFocus={!!croppedData}
                      style={{
                        width: (multiple && endAdornment) ? 'calc(100% - 20px)' : '100%'
                      }}
                      inputValue={foundOptionInputValue}
                      freeSolo
                      autoHighlight
                      onChange={(e, val) => {
                        const foundOption = optionList.filter(o => o.DisplayValue === val);

                        if(foundOption && foundOption.length) {
                          onInputChangeTriggered(foundOption[0].Value);
                        } else {
                          onInputChangeTriggered(val);
                        }
                      }}
                      options={optionList.map(option => option.DisplayValue)}
                      renderInput={params => {
                        return (
                          <TextField 
                            inputRef={inputRef}
                            value={params.inputProps.value}
                            data-input-resource-type={dataInputResourceType}
                            onFocus={onInputFocusTriggered}
                            onChange={e => {
                              setSelectionStart(e.target.selectionStart);
                              setSelectionEnd(e.target.selectionEnd);
                              onInputChangeTriggered(e.target.value)
                            }}
                            {...params} 
                              label={label} 
                              fullWidth 
                          />
                        )
                          
                      }}/>
                      {multiple && endAdornment ? endAdornment : null}
                  </React.Fragment>
          } else {
                return <React.Fragment>
                    {multiple && <Button size="small">
                        <Add style={{
                            cursor: 'pointer'
                        }} onClick={copy}
                        />
                    </Button>}
                    <Select
                        data-input-resource-type={dataInputResourceType}
                        onFocus={onInputFocusTriggered}
                        value={fieldValue}
                        style={{
                            width: (multiple && endAdornment) ? 'calc(100% - 20px)' : '100%'
                        }}
                        onChange={e => {
                          onInputChangeTriggered(e.target.value);
                        }}
                        align="left"
                        labelId={id}
                        {...htmlAttributes}>

                        {optionList.map((o, i) => (
                            <MenuItem key={i} value={o.Value}>{o.DisplayValue}</MenuItem>
                        ))}
                    </Select>
                </React.Fragment>
            }
        case 'text':
            return <React.Fragment>
                <Input
                    inputRef={inputRef}
                    data-input-resource-type={dataInputResourceType}
                    style={{
                        width: (multiple && endAdornment) ? 'calc(100% - 20px)' : '100%',
                    }}
                    onFocus={onInputFocusTriggered}
                    autoFocus={inputFocus}
                    value={fieldValue}
                    onChange={e => {
                      setSelectionStart(e.target.selectionStart);
                      setSelectionEnd(e.target.selectionEnd);

                      onInputChangeTriggered(e.target.value)
                    }}
                    {...htmlAttributes} />

                {multiple && endAdornment ? endAdornment : null}
            </React.Fragment>
        case 'date':
            if(croppedData) {
              return (
                <React.Fragment>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      KeyboardButtonProps={
                        {
                          style: {
                            display: 'none'
                          }
                        }
                        
                      }
                      onClick={() => {
                        onInputFocusTriggered(croppedData);
                      }}
                        data-input-resource-type={dataInputResourceType}
                        style={{
                            width: (multiple && endAdornment) ? 'calc(100% - 20px)' : '100%',
                        }}
                        onOpen={onInputFocusTriggered}
                        format="yyyy/MM/dd"
                        label={label}
                        value={fieldValue}
                        onChange={onInputChangeTriggered}
                    />
                </MuiPickersUtilsProvider>
                {multiple && endAdornment ? endAdornment : null}
            </React.Fragment>
              )
            } else {
              return (
                <React.Fragment>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}>
                        <DatePicker
                            data-input-resource-type={dataInputResourceType}
                            style={{
                                width: (multiple && endAdornment) ? 'calc(100% - 20px)' : '100%',
                            }}
                            onOpen={onInputFocusTriggered}
                            format="yyyy/MM/dd"
                            label={label}
                            value={fieldValue}
                            onChange={onInputChangeTriggered}
                        />
                    </MuiPickersUtilsProvider>
                    {multiple && endAdornment ? endAdornment : null}
                </React.Fragment>
            );
          }
        case 'checkbox':
            return (
                <React.Fragment>
                    {multiple && <Button size="small">
                        <Add style={{
                            cursor: 'pointer'
                        }} onClick={copy}
                        />
                    </Button>}
                    <FormGroup>
                        <FormControlLabel
                            style={{
                                textAlign: 'left'
                            }}
                            control={
                                <Checkbox
                                    color={classes.checkboxActiveColor}
                                    onChange={e => onInputChangeTriggered(e.target.checked)}
                                    {...htmlAttributes}
                                    value={fieldValue}/>
                            }
                            label={
                                <Typography style={{
                                    fontSize: '0.85rem'
                                }}>{label}</Typography>
                            }
                        />
                    </FormGroup>
                </React.Fragment>
            );
        default:
            return <React.Fragment>
                <Input
                    inputRef={inputRef}
                    data-input-resource-type={dataInputResourceType}
                    style={{
                        width: (multiple && endAdornment) ? 'calc(100% - 20px)' : '100%',
                    }}
                    onFocus={onInputFocusTriggered}
                    autoFocus={inputFocus}
                    value={fieldValue}
                    onChange={e => {
                      setSelectionStart(e.target.selectionStart);
                      setSelectionEnd(e.target.selectionEnd);

                      onInputChangeTriggered(e.target.value);
                    }}
                    {...htmlAttributes} />

                {multiple && endAdornment ? endAdornment : null}
            </React.Fragment>
    }
};

export default FormInput;