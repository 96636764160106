import {
  InvoiceDetailContactType,
  InvoicePredictionDetailType,
  InvoiceDetailType
} from './../../contants/invoice';

import { getTypeNameByRef } from './../form/invoice-form/formService';

function getInvoiceDetailTypeFieldType(
  invoicePredictionType
) {
  switch (invoicePredictionType) {
    case 'GrandTotalAmount':
    case 'VatRate':
    case 'NetTotalAmount':
    case 'VatAmount':
    case 'NetAmount':
    case 'VatTotalAmount':
      return 'number';
    case 'DeliveryDate':
    case 'InvoiceDate':
    case 'SenderOrderDate':
    case 'ReceiverOrderDate':
      return 'date';
    case 'DocumentType':
      return 'invoice-or-creditmemo';
    default:
      return 'text';
  }
}

export function flattenDetectInvoiceResponse(
  response,
) {
  function mapInvoiceDetailTypePredictions(
    predictions,
    groupId,
    groupIndex,
  ) {
    return predictions.map(({ TypeName, X, Y, Width, Height }, index) => ({
      Id: groupId ? `${groupId}-${TypeName}-${index}` : `${TypeName}-${index}`,
      Type: TypeName,
      X,
      Y,
      Width,
      Height,
      Path:
        typeof groupIndex === 'number'
          ? `BluDeltaResult.PredictionGroups[${groupIndex}].InvoiceDetailTypePredictions[${index}].Value`
          : `BluDeltaResult.InvoiceDetailTypePredictions[${index}].Value`,
      InputType: getInvoiceDetailTypeFieldType(TypeName),
    }));
  }

  function mapInvoiceDetailContactType(
    detail,
    detailType,
  ) {
    return Object.values(InvoiceDetailContactType).flatMap((contactKey) => {
      // address
      if (contactKey === InvoiceDetailContactType.Address) {
        const { Country, City, ZipCode, Street } = detail[contactKey];
        return [Country, City, ZipCode, Street].map(({ X, Y, Width, Height, Name }) => ({
          Id: `${detailType}-Address-${Name}`,
          Type: Name,
          X,
          Y,
          Width,
          Height,
          Path: `BluDeltaResult.${detailType}.Address.${Name}.Value`,
          InputType: 'text'
        }));
      }

      if (
        contactKey === InvoiceDetailContactType.Email ||
        contactKey === InvoiceDetailContactType.WebsiteUrl
      ) {
        // arrays
        return detail[contactKey].map(({ Name, X, Y, Width, Height }, index) => ({
          Id: `${detailType}-${Name}-${index}`,
          Type: Name,
          X,
          Y,
          Width,
          Height,
          Path: `BluDeltaResult.${detailType}.${contactKey}[${index}].Value`,
          InputType: 'text',
        }));
      }

      // objects
      const { Name, X, Y, Width, Height } = detail[contactKey];
      return {
        Id: `${detailType}-${Name}`,
        Type: Name,
        X,
        Y,
        Width,
        Height,
        Path: `BluDeltaResult.${detailType}.${contactKey}.Value`,
        InputType: 'text',
      };
    });
  }

  const invoiceDetailsMap = mapInvoiceDetailTypePredictions(
    response.InvoiceDetailTypePredictions,
    null,
    null,
    // the filter happens after the map to preserve correct index locations
  ).filter(({ Type }) => Type !== InvoicePredictionDetailType.Sender);

  const invoiceDetailsGroupsMap = [
    ...response.PredictionGroups.map(({ TypeName, InvoiceDetailTypePredictions }, index) => ({
      Id: `${TypeName}-${index}`,
      Type: TypeName,
      InvoiceDetailsMap: mapInvoiceDetailTypePredictions(
        InvoiceDetailTypePredictions,
        `${TypeName}-${index}`,
        index,
      ),
    })),
    ...Object.values(InvoiceDetailType)
      .filter((detailType) => !!response[detailType])
      .flatMap((detailType) => {
        const detail = response[detailType];

        if(detail) {
          return {
            Id: detailType,
            Type: detailType,
            InvoiceDetailsMap: mapInvoiceDetailContactType(detail, detailType),
          };
        }
      }),
  ];

  return { invoiceDetailsMap, invoiceDetailsGroupsMap };
}

export function getDetections(detectInvoiceResponse, formMap) {
  const detections = [];

  const { InvoiceDetailTypePredictions,  PredictionGroups } = detectInvoiceResponse;

  InvoiceDetailTypePredictions.forEach(prediction => {
    if(formMap[prediction.TypeName] && formMap[prediction.TypeName].ValueType === 'BludeltaRef') {
      const typeName = getTypeNameByRef(formMap[prediction.TypeName].BluDeltaFieldType);

      detections.push({
        Type: typeName,
        X: prediction.X,
        Y: prediction.Y,
        Width: prediction.Width,
        Height: prediction.Height,
        InputType: formMap[prediction.TypeName].InputType
      });
    }
  })

  PredictionGroups.forEach(group => {
    group.InvoiceDetailTypePredictions.forEach(prediction => {
      if(formMap[prediction.TypeName] && formMap[prediction.TypeName].ValueType === 'BludeltaRef') {
        const typeName = getTypeNameByRef(formMap[prediction.TypeName].BluDeltaFieldType);
        
        detections.push({
          Type: typeName,
          X: prediction.X,
          Y: prediction.Y,
          Width: prediction.Width,
          Height: prediction.Height,
          InputType: formMap[prediction.TypeName].InputType
        });
      }
    })
  })

  return detections;
}
