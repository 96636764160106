import React from 'react';
import FormControlWrapper from './FormControlWrapper';
import {Add} from '@material-ui/icons';
import {Button, Divider, FormControl, Typography} from '@material-ui/core';

const Form = props => {
    const {
        bludeltaMap,
        updateFormField,
        highlightedDetection,
        onFormInputFocus,
        copy,
        formErrors,
        classes,
        showScores,
        croppedData,
        resource
    } = props;

    const updateGroupItem = (value, ref, fieldIndex, groupIndex) => {
        updateFormField(value, ref, fieldIndex, groupIndex);
    };

    const [formData, setFormData] = React.useState(props.formData);
    const minimumScore = formData.MinimumScore;

    React.useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);

    return (
        <div style={{
            marginTop: '20px',
            paddingRight: '20px'
        }}>
            {/* <ComboBox /> */}
            {formData.FieldGroups.map((group, groupIndex) => (
                group.Visible ? (
                    <fieldset style={{
                        border: '1px solid #e4e4e4',
                        marginBottom: '20px'
                    }} key={`group-index-${groupIndex}`}>
                        <legend style={{
                            float: 'left',
                            position: 'relative',
                            marginTop: '-20px',
                            backgroundColor: '#fff',
                            padding: '5px'
                        }}>
                            {group.DisplayName}

                            {group.Multiple ? (
                                <Button size="small">
                                    <Add style={{
                                        cursor: 'pointer'
                                    }} onClick={() => copy(group, false, groupIndex)}/>
                                </Button>
                            ) : null}
                        </legend>

                        {group.Fields.map((field, fieldIndex) => (
                            field.Visible ? (
                                <FormControlWrapper
                                    croppedData={croppedData}
                                    classes={classes}
                                    onFormInputFocus={ref => onFormInputFocus(ref, fieldIndex, groupIndex)}
                                    highlightedDetection={highlightedDetection}
                                    onInputChanged={(value, ref) => updateGroupItem(value, ref, fieldIndex, groupIndex)}
                                    bludeltaMap={bludeltaMap}
                                    copy={copy}
                                    minimumScore={minimumScore}
                                    groupIndex={groupIndex}
                                    showScores={showScores}
                                    error={!!formErrors[groupIndex][fieldIndex]}
                                    errorMessage={formErrors[groupIndex][fieldIndex]}
                                    index={fieldIndex}
                                    key={`field-index-${fieldIndex}`}
                                    field={field}/>
                            ) : null
                        ))}
                    </fieldset>
                ) : null
            ))}
        </div>
    )
};

export default Form;