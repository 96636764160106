import React from 'react';
import Button from '@material-ui/core/Button';
import GridOnIcon from '@material-ui/icons/GridOn';
import GridOffIcon from '@material-ui/icons/GridOff';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import Box from '@material-ui/core/Box';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const InvoiceTools = ({
                          onTriggerOutlined,
                          onTriggerCropMode,
                          outlined,
                          cropMode,
                          invoiceBase64,
                          fileType,
                          onTriggerMagnifier,
                          magnifierMode,
                          onTriggerFullScreenMode,
                          submitFunc,
                          classes,
                          fullScreenMode
                      }) => {

    return (
        <Box
            style={{
                width: '100%'
            }}
            display="flex"
            justifyContent="space-between"
            m={1}
            p={1}>
            <Box
                display="flex"
                justifyContent="space-between">

                <Box mr={1}>
                    <Button
                        variant="contained"
                        className={outlined ? classes.appBarButtonBackgroundColorActive : classes.appBarButtonBackgroundColorDefault}
                        onClick={onTriggerOutlined}>
                        {outlined ? (
                            <GridOffIcon/>
                        ) : (
                            <GridOnIcon/>
                        )}
                    </Button>
                </Box>

                {
                    fileType === 'jpg' ? (
                        <Box mr={1}>
                            <Button
                                variant="contained"
                                className={magnifierMode ? classes.appBarButtonBackgroundColorActive : classes.appBarButtonBackgroundColorDefault}
                                onClick={onTriggerMagnifier}>
                                {magnifierMode ? (
                                    <ZoomOutIcon/>
                                ) : (
                                    <ZoomInIcon/>
                                )}
                            </Button>
                        </Box>
                    ) : null
                }

                <Box mr={1}>
                    <Button
                        variant="contained"
                        className={fullScreenMode ? classes.appBarButtonBackgroundColorActive : classes.appBarButtonBackgroundColorDefault}
                        onClick={onTriggerFullScreenMode}>
                        {fullScreenMode ? (<FullscreenExitIcon/>) : (
                            <FullscreenIcon/>
                        )}
                    </Button>
                </Box>
                <Box mr={1}>
                    <Button
                        className={classes.appBarButtonBackgroundColorDefault}
                        download={`invoice-${new Date().getTime()}.${fileType}`}
                        variant="contained"
                        href={`data:image;base64,${invoiceBase64}`}>
                        <CloudDownloadIcon/>
                    </Button>
                </Box>
                <Box mr={1}>
                    <Button
                        className={cropMode ? classes.appBarButtonBackgroundColorActive : classes.appBarButtonBackgroundColorDefault}
                        variant="contained"
                        onClick={onTriggerCropMode}>
                        {cropMode ? (
                            <CenterFocusStrongIcon/>
                        ) : (
                            <CenterFocusWeakIcon/>
                        )}
                    </Button>
                </Box>
            </Box>

            <Box
                display="flex"
                justifyContent="space-between">
                <Box mr={1}>
                    <Button className={classes.appBarButtonBackgroundColorDefault} onClick={() => submitFunc()}
                            variant="contained">
                        <SaveAltIcon/>
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default InvoiceTools;