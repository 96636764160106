import React from 'react';

export const HIGHLIGHTED_PADDING = 5;
export const OUTLINE_SIZE = 5;

const OCRTextPageDetectionHighlighter = ({
  scale,
  detection,
  onDetectionClick,
  highlightedDetection
}) => {
  const top = detection.Y * scale;
  const left = detection.X * scale;
  const width = detection.Width * scale;
  const height = detection.Height * scale;
  const [highlighted, setHighlighted] = React.useState(false);

  React.useEffect(() => {
    if(highlightedDetection && highlightedDetection.Type === detection.Type) {
      setHighlighted(true);
    } else {
      setHighlighted(false);
    }
  }, [highlightedDetection])

  const onDetectionWordClick = detection => {
    const inputEl = document.querySelectorAll(`[data-input-resource-type="${detection.Type}"] input`)[0];

    if (inputEl) {
      inputEl.focus();
    }
  }

  return (
    <div 
      onClick={() => onDetectionClick(detection)}
      data-resource-path={detection.Path}
      style={{
        position: 'absolute',
        top: top - HIGHLIGHTED_PADDING / 2,
        left: left - HIGHLIGHTED_PADDING / 2,
        width: width + HIGHLIGHTED_PADDING,
        height: height + HIGHLIGHTED_PADDING,
        border: highlighted ? 'none' : `${OUTLINE_SIZE}px solid lightgreen`,
        outline: highlighted ? `${OUTLINE_SIZE}px solid lightgreen` : 'none',
        backgroundColor: highlighted ? 'transparent' : 'lightgreen',
        cursor: 'pointer',
        opacity: highlighted ? 1 : 0.5,
        zIndex: 1
      }}
    />
  )
}

export default OCRTextPageDetectionHighlighter;