import React, { useEffect } from 'react';

const Cropper = ({
  show,
  x1,
  x2,
  y1,
  y2
}) => {
  const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

  return (
    <div 
      style={{
        border: '1px dotted #000',
        position: 'absolute',
        left: Math.min(x1, x2),
        top: Math.min(y1, y2) + scrollTop,
        width: Math.max(x1, x2) - Math.min(x1, x2),
        height: Math.max(y1, y2) - Math.min(y1, y2),
        display: show ? 'block' : 'none',
        zIndex: 9
      }}></div>
  )
}

Cropper.defaultProps = {
  x1: 0,
  x2: 0,
  y1: 0,
  y2: 0
}

export default Cropper;