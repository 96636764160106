import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { ocrBoundingBoxToPosition } from './ocrService';

const useStyles = makeStyles(theme => ({
  word: {
    color: 'transparent',
    '&::-moz-selection': {
      color: 'transparent',
      backgroundColor: 'rgba(0,0,255,0.2)',
    },
    '&::selection': {
      color: 'transparent',
      backgroundColor: 'rgba(0,0,255,0.2)',
    }
  }
}));


const OCRWord = ({ 
  word, 
  scale,
  outlined,
}) => {
  const classes = useStyles();
  const { top, left, width, height } = ocrBoundingBoxToPosition(word.BoundingBox);
  const fontHeight = height * scale;
  const textWidth = React.useMemo(() => {
    const context = document.createElement('canvas').getContext('2d');
    if (!context) {
      return 0;
    }
    context.font = `${fontHeight}px sans-serif`;
    const textWidth = context.measureText(word.Text).width;
    return textWidth;
  }, [word.Text, fontHeight]);
  
  return (
    <span 
      className={classes.word}
      style={{
        outline: outlined ? '1px solid blue' : 'none',
        fontFamily: 'Open Sans',
        fontWeight: word.Bold ? 'bold' : undefined,
        fontStyle: word.Italic ? 'italic' : undefined,
        textDecoration: word.Underlined ? 'underline' : undefined,
        position: 'absolute',
        top: top * scale,
        left: left * scale,
        width: width * scale,
        height: height * scale,
        fontSize: fontHeight,
        letterSpacing: (width * scale - textWidth) / word.Text.length,
        whiteSpace: 'pre',
        
      }}>
      {word.Text}
    </span>
  )
}

export default OCRWord;