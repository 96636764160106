import React from 'react';
import PdfPage from './PdfPage';
import CircularProgress from '@material-ui/core/CircularProgress';

const PdfViewer = ({ 
  invoiceBase64, 
  updateWidthAndHeight,
  canvasWidth,
  canvasHeight
}) => {
  const pdfData = atob(invoiceBase64);
  const [pdf, setPdf] = React.useState(null);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [pdfLoaded, setPdfLoaded] = React.useState(false);
  const [numberOfPagesLoaded, setNumberOfPagesLoaded] = React.useState(0);

  React.useEffect(() => {
    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.worker.js';

    // Opening PDF by passing its binary data as a string. It is still preferable
    // to use Uint8Array, but string or array-like structure will work too.
    var loadingTask = window.pdfjsLib.getDocument({ data: pdfData, });
    loadingTask.promise.then(function(pdf) {
      const { numPages } = pdf;

      setNumberOfPages(Array(numPages).fill(0));
      setPdf(pdf);
    });
  }, []);

  const onPageLoaded = () => {
    setNumberOfPagesLoaded(numberOfPagesLoaded + 1);
  }

  return (
    <>
      {(numberOfPages !== numberOfPagesLoaded) || <CircularProgress disableShrink />}
      {(pdf && numberOfPages) ? numberOfPages.map((p, i) => (
        <PdfPage 
          canvasWidth={canvasWidth}
          key={`pdf-page-${i}`} 
          pdf={pdf} 
          page={i+ 1}
          updateWidthAndHeight={updateWidthAndHeight}
          onPageLoaded={onPageLoaded} />
      )) : null }
    </>
  )
}

export default PdfViewer;