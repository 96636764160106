const extractOcrBoundingBoxRectanglePosition = (rectangle, position) => {
  const matches = rectangle.match(new RegExp(`(${position}=)(\\d+)`));
  if (!matches || !matches[2]) {
    throw new Error('Malformed OCRBoundingBox');
  }
  return parseInt(matches[2].trim());
};

export function ocrBoundingBoxToPosition(boundingBox) {
  try {
    return {
      top: extractOcrBoundingBoxRectanglePosition(boundingBox.Rectangle, 'Y'),
      left: extractOcrBoundingBoxRectanglePosition(boundingBox.Rectangle, 'X'),
      width: extractOcrBoundingBoxRectanglePosition(boundingBox.Rectangle, 'Width'),
      height: extractOcrBoundingBoxRectanglePosition(boundingBox.Rectangle, 'Height'),
    };
  } catch (error) {
    const [left, top, width, height] = boundingBox.Rectangle.split(',');
    return {
      top: parseInt(top.trim(), 10),
      left: parseInt(left.trim(), 10),
      width: parseInt(width.trim(), 10),
      height: parseInt(height.trim(), 10),
    };
  }
}