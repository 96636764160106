import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import {
    Grid, Paper, CssBaseline, AppBar, Divider, Drawer, Snackbar, IconButton, Button, Toolbar
} from '@material-ui/core';
import Form from './components/form/invoice-form/Form';
import InvoicePreviewer from './components/invoice/InvoicePreviewer';
import InvoiceTools from './components/invoice/InvoiceTools';
import Cropper from './components/cropper/Cropper';
import {ocrBoundingBoxToPosition} from './components/invoice/ocr/ocrService';
import {Scrollbars} from 'react-custom-scrollbars';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    initializeFormErrors,
    submit,
    updateFieldValueFromBludeltaStore,
    validateField,
    validateForm,
    getTypeNameByRef,
    getFieldValueFromBludeltaStore
} from './components/form/invoice-form/formService';

let useStyles = makeStyles(theme => ({
    appBar: {
        width: `calc(100% - 400px)`,
        marginLeft: 400
    },
    appBarFullScreen: {
        width: '100%',
    },
    root: {
        display: 'flex',
        backgroundColor: '#E0E0E0',
        overflow: 'hidden' // important for multiple pages render
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: '#fff'
    },
    drawer: {
        width: 400,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 400
    },
    hideDrawer: {
        width: 0
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        paddingTop: 100
    },
    appBarBackgroundColor: {
        'background-color': '#001E3C'
    },
    appBarButtonBackgroundColorDefault: {
        '&:hover': {
            'background-color': '#e0e0e0'
        },
        'background-color': '#e0e0e0'
    },
    appBarButtonBackgroundColorActive: {
        '&:hover': {
            'background-color': '#b50d2e'
        },
        'background-color': '#b50d2e'
    },
    showScoresBtnDefault: {
        '&:hover': {
            'background-color': '#e0e0e0'
        },
        'margin': '20px 0 0 0',
        'background-color': '#e0e0e0',
        'color': '#ffffff'
    },
    showScoresBtnActive: {
        '&:hover': {
            'background-color': '#3f51b5'
        },
        'margin': '20px 0 0 0',
        'background-color': '#3f51b5',
        'color': '#ffffff'
    },
    checkboxActiveColor: {
        'color': '#f50057'
    }
}));

function App() {
    const [resource, setResource] = useState(null);
    const [formErrors, setFormErrors] = useState(null);
    const [errors, setErrors] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [invoiceBase64, setInvoiceBase64] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [outlined, setOutlined] = useState(false);
    const [cropMode, setCropMode] = useState(false);
    const [magnifierMode, setMagnifierMode] = useState(false);
    const [fullScreenMode, setFullScreenMode] = useState(false);
    const [displayCropperArea, setDisplayCropperArea] = React.useState(false);
    const [scale, setScale] = React.useState(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [openFormSnackbar, setOpenFormSnackbar] = React.useState(false);
    const [formSnackbarMessage, setFormSnackbarMessage] = React.useState('');
    const [bludeltaMap, setBludeltaMap] = React.useState('');
    const [predictionsFormMap, setPredictionsFormMap] = React.useState(null);
    const [showScores, setShowScores] = React.useState(false);
    const [cropperCoordinates, setCrooperCoordinates] = React.useState({
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
    });
    const [croppedData, setCroppedData] = React.useState(null);
    const [highlightedDetection, setHighlightedDetection] = React.useState(null);
    let classes = useStyles();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fileParam = urlParams.get('file');
    const scrollbar = React.createRef();

    // default example http://localhost:3000/?file=valid_16fc304f440_ab8ca695de742.json
    // jpg example http://localhost:3000/?file=valid_16fc304f440_bf402905da7a1.json
    // pdf multiple pages http://localhost:3000/?file=valid_16fc304f440_bedf84271030b.json
    useEffect(() => {
        axios.get(`/getValidationFile?file=${fileParam}`, {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(({data}) => {
                setResource(data);
                setFileType(data.FileType);
                setInvoiceBase64(data.Invoice);

                const resourceWithCopiedValues = JSON.parse(JSON.stringify(data));

                console.log('resourceWithCopiedValues', resourceWithCopiedValues);

                const bludeltaMapStore = JSON.parse(JSON.stringify(data.BluDeltaResult));
                bludeltaMapStore['InvoiceDetailTypePredictions'] = {};
                bludeltaMapStore['PredictionGroups'] = {};

                data.BluDeltaResult.InvoiceDetailTypePredictions.forEach(prediction => {
                    bludeltaMapStore['InvoiceDetailTypePredictions'][prediction.TypeName] = prediction;
                });

                data.BluDeltaResult.PredictionGroups.forEach(prediction => {
                    prediction.InvoiceDetailTypePredictions.forEach(p => {
                        bludeltaMapStore['PredictionGroups'][p.TypeName] = p;
                    })
                });

                const formMapStore = {};

                data.Form.FieldGroups.forEach(fieldGroup => {
                  fieldGroup.Fields.forEach(field => {
                    formMapStore[field.Name] = field;
                  })
                });

                setPredictionsFormMap(formMapStore);

                setBludeltaMap(bludeltaMapStore);
                setFormErrors(validateForm(data.Form, initializeFormErrors(data.Form)));

                resourceWithCopiedValues.Form.FieldGroups.forEach((group, groupIndex) => {
                  group.Fields.forEach((field, fieldIndex) => {
                    if(field.ValueType === 'BludeltaRef') {
                      const fieldValue = field.Value === undefined ? '' : field.Value;
                      const refValue = getFieldValueFromBludeltaStore(bludeltaMapStore, field.BluDeltaFieldType, fieldValue, resourceWithCopiedValues.Form.MinimumScore);
                      resourceWithCopiedValues.Form.FieldGroups[groupIndex].Fields[fieldIndex].Value = refValue;
                    }
                  })
                })

                setResource(resourceWithCopiedValues);

                // TODO: Uncomment once we have example with css
                // if (data.Form.Stylesheet) {
                //     let styles = JSON.parse(JSON.stringify(data.Form.Stylesheet));
                //     styles = makeStyles(theme => ({...useStyles, ...styles}));
                //     classes = styles();
                // }
                setShowScores(data.Form.ShowScoreRates);
                setDataLoaded(true);
            })
            .catch(function (error) {
                setErrors(true);
                setDataLoaded(true);
            })
    }, []);

    useEffect(() => {
        if (highlightedDetection) {

            const inputEl = document.querySelectorAll(`[data-input-resource-type="${highlightedDetection.Type}"] input`)[0];

            if (inputEl) {
                inputEl.focus();
            }
        }
    }, [highlightedDetection]);

    const invoiceOnMouseDown = e => {
        const x1 = e.clientX;
        const y1 = e.clientY;

        setCrooperCoordinates({...cropperCoordinates, ...{x1, y1}});
        setDisplayCropperArea(true);
    }

    const invoiceOnMouseMove = e => {
        const x2 = e.clientX;
        const y2 = e.clientY;
        setCrooperCoordinates({...cropperCoordinates, ...{x2, y2}});
    }

    const onScaleUpdate = scaleVal => {
        setScale(scaleVal);
    }

    const invoiceOnMouseUp = e => {
        setDisplayCropperArea(false);
        const foundElements = [];
        const {Pages} = JSON.parse(resource.BluDeltaResult.OcrResult);
        const invoicePreviewerEl = document.getElementById('invoicePreviewer');

        if (invoicePreviewerEl) {
            const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            const viewportOffset = invoicePreviewerEl.getBoundingClientRect();

            const croppedArealeft = Math.min(cropperCoordinates.x1, cropperCoordinates.x2) - viewportOffset.left;
            const croppedAreaTop = Math.min(cropperCoordinates.y1, cropperCoordinates.y2) - viewportOffset.top;
            const croppedAreaWidth = Math.max(cropperCoordinates.x1, cropperCoordinates.x2) - Math.min(cropperCoordinates.x1, cropperCoordinates.x2);
            const croppedAreaHeight = Math.max(cropperCoordinates.y1, cropperCoordinates.y2) - Math.min(cropperCoordinates.y1, cropperCoordinates.y2);

            Pages.forEach(page => {
                page.Regions.forEach(region => {
                    region.Lines.forEach(line => {
                        line.Words.forEach(word => {
                            const {top, left, width, height} = ocrBoundingBoxToPosition(word.BoundingBox);

                            const coordinates = {
                                top: top * scale,
                                left: left * scale,
                                width: width * scale,
                                height: height * scale
                            }

                            word.coordinates = coordinates;

                            if (
                                croppedAreaTop <= coordinates.top &&
                                croppedArealeft <= coordinates.left &&
                                (croppedArealeft + croppedAreaWidth) >= (coordinates.left + coordinates.width) &&
                                (croppedAreaHeight + croppedAreaTop) >= (coordinates.height + coordinates.top)) {

                                foundElements.push(word);
                            }
                        })
                    })
                })
            });

            let croppedString = '';

            foundElements.forEach((w, i) => {
                if (foundElements.length === (i + 1)) {
                    // last element
                    croppedString = croppedString.concat(w.Text);
                } else {
                    croppedString = croppedString.concat(`${w.Text} `);
                }
            });

            if (croppedString) {
                setSnackbarMessage(`Copied: ${croppedString}.`);
                setOpenSnackbar(true);
                setCroppedData(croppedString);
            }
        }
    };

    const updateFormField = (value, ref, fieldIndex, groupIndex) => {
        if (ref) {
            const bludeltaMapCopy = updateFieldValueFromBludeltaStore(JSON.parse(JSON.stringify(bludeltaMap)), ref, value);
            setBludeltaMap(bludeltaMapCopy);
        }

        const resourceCopy = JSON.parse(JSON.stringify(resource));
        const formErrorsCopy = JSON.parse(JSON.stringify(formErrors));
        const field = resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex];

        if (field.InputType === 'checkbox') {
            field.Checked = value;
            resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex].Checked = value;
        } else {
            field.Value = value;
            resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex].Value = value;
        }

        setFormErrors(validateField(value, field, formErrorsCopy, groupIndex, fieldIndex));
        setResource(resourceCopy);
    };

    const copy = (element, isField, groupIndex, fieldIndex) => {
        const resourceCopy = JSON.parse(JSON.stringify(resource));

        if (isField) {
            resourceCopy.Form.FieldGroups[groupIndex].Fields.splice(fieldIndex, 0, element);
            resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex+1].ValueType = 'Fix';
            delete resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex+1].ValueType.BluDeltaFieldType;
        } else {
            resourceCopy.Form.FieldGroups.splice(groupIndex, 0, element);
            resourceCopy.Form.FieldGroups[groupIndex+1].Fields.map(field => {
                field.ValueType = 'Fix';
                delete field.BluDeltaFieldType;
                return field;
            });
        }

        setFormErrors(validateForm(resourceCopy.Form, initializeFormErrors(resourceCopy.Form)));
        setResource(resourceCopy);
    };

    const onFormInputFocus = (ref, fieldIndex, groupIndex) => {
        if (croppedData) {
            const resourceCopy = JSON.parse(JSON.stringify(resource));

            if (ref) {
                const bludeltaMapCopy = updateFieldValueFromBludeltaStore(JSON.parse(JSON.stringify(bludeltaMap)), ref, croppedData);
                setBludeltaMap(bludeltaMapCopy);
            }

            resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex].Value = croppedData;

            if (resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex].OptionList) {
                const valueExists = resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex].OptionList.filter(d => (
                    d.DisplayValue === croppedData
                ))

                if (valueExists.length === 0) {
                    resourceCopy.Form.FieldGroups[groupIndex].Fields[fieldIndex].OptionList.unshift({
                        DisplayValue: croppedData,
                        Value: croppedData
                    })
                }
            }

            setResource(resourceCopy);
        }

        if (ref) {
            const typeName = getTypeNameByRef(ref);

            if (typeName) {
                // todo: this doesn't work with multiple input fields
                // setHighlightedDetection({
                //   Type: typeName
                // })
            }
        }

        setCroppedData(null);
    };

    const submitFunc = () => {
        submit(JSON.parse(JSON.stringify(resource)))
            .then(res => {
                setFormSnackbarMessage(`Success`);
            })
            .catch(ex => {
                setFormSnackbarMessage(ex.message);
            }).finally(() => {
            setOpenFormSnackbar(true);
        });
    };

    if (!dataLoaded) {
        return <CircularProgress style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto'
        }} color="primary"/>
    }

    if (dataLoaded && errors) {
        return <div>Sorry, something went wrong. Please make sure you have url with a correct file parameter.</div>
    }

    return (
        <div className={classes.root}>
            <Cropper
                show={displayCropperArea}
                {...cropperCoordinates}
            />

            <CssBaseline/>

            <AppBar
                className={!fullScreenMode ? `${classes.appBarBackgroundColor} ${classes.appBar}` : `${classes.appBarBackgroundColor}`}
                position="fixed">
                <Toolbar>
                    <InvoiceTools
                        fullScreenMode={fullScreenMode}
                        classes={classes}
                        magnifierMode={magnifierMode}
                        fileType={fileType}
                        invoiceBase64={invoiceBase64}
                        cropMode={cropMode}
                        outlined={outlined}
                        submitFunc={submitFunc}
                        onTriggerOutlined={() => setOutlined(!outlined)}
                        onTriggerMagnifier={() => (setMagnifierMode(!magnifierMode), setCropMode(false))}
                        onTriggerCropMode={() => (setCropMode(!cropMode), setMagnifierMode(false), setCroppedData(null))}
                        onTriggerFullScreenMode={() => setFullScreenMode(!fullScreenMode)}/>
                </Toolbar>
            </AppBar>

            {!fullScreenMode ? (
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >
                    <Divider/>

                    <Paper style={{
                        height: '100%',
                        paddingRight: 0
                    }} className={classes.paper}>
                        {resource.Form.Logo ? (
                            <img style={{
                                maxHeight: 50
                            }} src={`data:image/png;base64, ${resource.Form.Logo}`}/>
                        ) : null}

                        <Divider/>

                        <Scrollbars
                            ref={scrollbar}
                            style={{
                                height: 'calc(100% - 40px)'
                            }}>
                            <Form
                                style={{
                                    paddingRight: 10
                                }}
                                classes={classes}
                                formErrors={formErrors}
                                onFormInputFocus={onFormInputFocus}
                                croppedData={croppedData}
                                formData={resource.Form}
                                bludeltaMap={bludeltaMap}
                                updateFormField={updateFormField}
                                copy={copy}
                                highlightedDetection={highlightedDetection}
                                showScores={showScores}
                            />
                        </Scrollbars>
                    </Paper>
                </Drawer>
            ) : null}

            <main className={classes.content}>
                <Grid container spacing={3}>
                    <Snackbar
                        open={openSnackbar && croppedData}
                        message={snackbarMessage}
                    />
                    <Snackbar
                        onClose={() => setOpenFormSnackbar(false)}
                        open={openFormSnackbar}
                        message={formSnackbarMessage}
                        autoHideDuration={6000}
                    />
                    <Grid item xs={12} className={classes.invoiceGridContainer}>
                        <Paper
                            className={classes.paper}>
                            <div id="invoicePreviewRoot" style={{
                                width: '100%',
                                display: 'inline-block'
                            }}>
                                <InvoicePreviewer
                                    bludeltaMap={bludeltaMap}
                                    predictionsFormMap={predictionsFormMap}
                                    magnifierMode={magnifierMode}
                                    highlightedDetection={highlightedDetection}
                                    setHighlightedDetection={setHighlightedDetection}
                                    detectInvoiceResponse={resource.BluDeltaResult}
                                    invoiceOnMouseDown={invoiceOnMouseDown}
                                    invoiceOnMouseMove={invoiceOnMouseMove}
                                    invoiceOnMouseUp={invoiceOnMouseUp}
                                    cropMode={cropMode}
                                    outlined={outlined}
                                    fileType={fileType}
                                    ocrResult={JSON.parse(resource.BluDeltaResult.OcrResult)}
                                    invoiceBase64={invoiceBase64}
                                    onScaleUpdate={onScaleUpdate}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}

export default App;
