import React from 'react';
import { ocrBoundingBoxToPosition } from './ocrService';
import OCRWord from './OCRWord';

const OCRLine = ({ 
  line, 
  scale,
  parentTop,
  parentLeft,
  outlined,
  outlinedWords
}) => {
  const { top, left, width, height } = ocrBoundingBoxToPosition(line.BoundingBox);

  return (
    <div
      style={{
        top: (top - parentTop) * scale,
        left: (left - parentLeft) * scale,
        width: width * scale,
        height: height * scale,
        fontFamily: 'Open Sans'
      }}>
      {line.Words.map((word, wordIndex) => (
        <OCRWord 
          key={`word-${wordIndex}`}
          word={word}
          scale={scale}
          parentTop={top}
          parentLeft={left}
          outlined={outlined}
          outlinedWords={outlinedWords} />
      ))}
    </div>
  )
}

export default OCRLine;