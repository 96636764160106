import React from 'react';
import OCRRegion from './OCRRegion';
import { flattenDetectInvoiceResponse, getDetections } from './../flatInvoiceDetails';
import OCRTextPageDetectionHighlighter from './OCRTextPageDetectionHighlighter';

const OCRWrapper = ({
  ocrResult,
  canvasWidth,
  canvasHeight,
  detectInvoiceResponse,
  outlined,
  setHighlightedDetection,
  highlightedDetection,
  onScaleUpdate,
  predictionsFormMap
}) => {
  const [scale, setScale] = React.useState(1);
  const { Pages, Width, Height } = ocrResult;
  // const { Regions } = Pages[0];

  React.useEffect(() => {
    const scaleValue = canvasWidth / Width;
    setScale(scaleValue);
    onScaleUpdate(scaleValue);
  }, [canvasWidth, Width]);

  // const { invoiceDetailsMap, invoiceDetailsGroupsMap } = flattenDetectInvoiceResponse(detectInvoiceResponse);

  // const detections = [
  //   ...invoiceDetailsMap,
  //   ...invoiceDetailsGroupsMap.flatMap(({ InvoiceDetailsMap }) => InvoiceDetailsMap)
  // ]

  const detections = getDetections(detectInvoiceResponse, predictionsFormMap);

  // console.log('**** Detections', detections);

  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      fontFamily: 'Open Sans'
    }}>

      {Pages.map((p, pageIndex) => (
        <React.Fragment key={`page-key-${pageIndex}`}>
          {/* Detections */}
          {detections.map((detection, index) => {
            if(detection.Width !== 0 && detection.Height !== 0) {
              return (
                <OCRTextPageDetectionHighlighter 
                  highlightedDetection={highlightedDetection}
                  onDetectionClick={setHighlightedDetection}
                  key={`detection-key-${index}`}
                  scale={scale}
                  detection={detection}
                />
              )
            }
          })}

          {p.Regions.map((region, regionIndex) => (
            <OCRRegion 
              key={regionIndex}
              region={region}
              scale={scale}
              outlined={outlined}
              outlinedPage={outlined}
              outlinedRegions={outlined}
              outlinedLines={outlined}
              outlinedWords={outlined} />
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

export default OCRWrapper;

