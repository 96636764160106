import React from 'react';

const PdfPage = ({ 
  pdf,
  page,
  updateWidthAndHeight,
  onPageLoaded,
  canvasWidth
}) => {
  const [pageResource, setPageResource] = React.useState(null);
  const canvasRef = React.createRef();

  React.useEffect(() => {
    pdf.getPage(page)
      .then(function(response) {
        let canvasWidthState = 1300;

        if(window.innerWidth > 1850) {
          canvasWidthState = 900;
        } else if(window.innerWidth > 1600) {
          canvasWidthState = 700;
        } else if(window.innerWidth > 1400) {
          canvasWidthState = 600;

        } else if(window.innerWidth > 1300) {
          canvasWidthState = 500;
        } else if (window.innerWidth > 900) {
          canvasWidthState = 400;
        }

        const viewport = response.getViewport(canvasWidthState / response.getViewport(1.0).width);
        
        setPageResource(response);

        // Prepare canvas using PDF page dimensions.
        var canvas = document.getElementById(`pdf-page-${page}`);
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context.
        var renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        response.render(renderContext);

        onPageLoaded();
        
        updateWidthAndHeight({
          width: canvas.width,
          height: canvas.height
        })
      });
  }, [canvasWidth]);

  return (
    <>
      { pageResource ? (
        <canvas ref={canvasRef} style={{
          display: 'block'
        }} id={`pdf-page-${page}`}></canvas>
      ) : null}
    </>
  )
}

export default PdfPage;