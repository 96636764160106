import React from 'react';
import OCRWrapper from './ocr/OCRWrapper';
import PdfViewer from './PdfViewer';
import Magnifier from "react-magnifier";

const InvoicePreviewer = ({
  fileType,
  ocrResult,
  invoiceBase64,
  outlined,
  cropMode,
  invoiceOnMouseDown,
  invoiceOnMouseMove,
  invoiceOnMouseUp,
  detectInvoiceResponse,
  highlightedDetection,
  setHighlightedDetection,
  onScaleUpdate,
  magnifierMode,
  predictionsFormMap,
  bludeltaMap
}) => {
  const [canvasWidth, setCanvasWidth] = React.useState(0);
  const [canvasHeight, setCanvasHeight] = React.useState(0);
  const [imageWidth, setImageWidth] = React.useState(0);

  const updateCanvasWidthAndHeight = ({ width, height}) => {
    if(!canvasWidth) {
      setCanvasWidth(width);
      setCanvasHeight(height);
    }
  }

  const updateImageWidthAndHeight = ({ target: img }) => {
    let imageWidth;

    if(window.innerWidth > 1850) {
      imageWidth = 900;
    } else if(window.innerWidth > 1600) {
      imageWidth = 700;
    } else if(window.innerWidth > 1400) {
      imageWidth = 600;

    } else if(window.innerWidth > 1300) {
      imageWidth = 500;
    } else if (window.innerWidth > 900) {
      imageWidth = 400;
    }

    setImageWidth(imageWidth);
  }

  React.useEffect(() => {
    let resizeTimeout = null;

    const resizedw = () => {
      let invoiceWidth;

      if(window.innerWidth > 1850) {
        invoiceWidth = 900;
      } else if(window.innerWidth > 1600) {
        invoiceWidth = 700;
      } else if(window.innerWidth > 1400) {
        invoiceWidth = 600;

      } else if(window.innerWidth > 1300) {
        invoiceWidth = 500;
      } else if (window.innerWidth > 900) {
        invoiceWidth = 400;
      }

      if(fileType === 'pdf') {
        setCanvasWidth(invoiceWidth)
      } else {
        setImageWidth(invoiceWidth);
      }
    }
    
    window.onresize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(resizedw, 100);
    };
  }, [])


  return (
    <div id="invoicePreviewer" style={{
      position: 'relative',
      display: 'inline-block',
      outline: '1px solid #000'
    }}>
      <input type="hidden" id="invoiceAreaWidth" value={fileType === 'pdf' ? canvasWidth : imageWidth} />
      {cropMode ? (
        <div 
          id="cropperArea"
          onMouseDown={invoiceOnMouseDown}
          onMouseMove={invoiceOnMouseMove}
          onMouseUp={invoiceOnMouseUp}
          style={{ 
            position: 'absolute',
            zIndex: 9,
            display: 'inline-block',
            width: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}>
          
        </div>
      ) : null}
      
      {fileType === 'pdf' ? (
        <div id="invoiceWrapper" 
          style={{ 
            display: 'inline-block',
            position: 'relative' 
          }}>
            {(canvasWidth && canvasHeight) ? (
              <OCRWrapper
                bludeltaMap={bludeltaMap}
                predictionsFormMap={predictionsFormMap}
                highlightedDetection={highlightedDetection}
                setHighlightedDetection={setHighlightedDetection}
                detectInvoiceResponse={detectInvoiceResponse}
                outlined={outlined}
                ocrResult={ocrResult}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                onScaleUpdate={onScaleUpdate} /> 
            ) : null}

          <PdfViewer 
            invoiceBase64={invoiceBase64}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            updateWidthAndHeight={updateCanvasWidthAndHeight} />
        </div>
      ) : (
        <div id="invoiceWrapper" 
          style={{
            display: 'inline-block',
            position: 'relative',
            width: imageWidth
          }}>
          {(imageWidth) ? (
              <OCRWrapper
                bludeltaMap={bludeltaMap}
                predictionsFormMap={predictionsFormMap}
                highlightedDetection={highlightedDetection}
                setHighlightedDetection={setHighlightedDetection}
                detectInvoiceResponse={detectInvoiceResponse}
                outlined={outlined}
                ocrResult={ocrResult}
                canvasWidth={imageWidth}
                onScaleUpdate={onScaleUpdate} /> 
            ) : null}

          {magnifierMode ? (
            <Magnifier 
              style={{
                width: '100%'
              }}
              onLoad={updateImageWidthAndHeight} 
              src={`data:image/jpeg;base64, ${invoiceBase64}`}
              mgWidth={200}
              mgHeight={200}
            />
          ) : (
            <img 
              width={imageWidth}
              onLoad={updateImageWidthAndHeight} 
              src={`data:image/jpeg;base64, ${invoiceBase64}`} />
          )}
          
        </div>
      )}
    </div>
  )
}

export default InvoicePreviewer;